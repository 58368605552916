::-webkit-scrollbar{width:2px;height:2px;}
::-webkit-scrollbar-button{width:2px;height:2px;}

div{
  box-sizing:border-box;
}


.horizontal-scroll-wrapper{
  /* position:absolute; */
  display:block;
  top:0;
  left:0;
  /* width:100%x; */
  max-height:500px;
  margin:0;
  background:rgb(225, 229, 233);
  overflow-y:auto;
  overflow-x:hidden;
  transform:rotate(-90deg) translateY(-80px);
  transform-origin:right top;
}
.horizontal-scroll-wrapper > div{
  display:block;
  padding:5px;
  background:#cab;
  transform:rotate(90deg);
  transform-origin: right top;
}

.squares{
  padding:60px 0 0 0;
}

.squares > div{
  width:60px;
  height:60px;
  margin:10px;
}

.rectangles{
  top:100px;
  padding:100px 0 0 0;
}
.rectangles > div{
  width:140px;
  height:60px;
  margin:50px 10px;
  padding:5px;
  background:#cab;
  transform: translateX(80px);
  transform-origin: right top;
}


